













































































































































import { Component, Vue } from "vue-property-decorator";
import { Course, CourseCategory, Program } from "@/types";
import axios from "axios";
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import CourseCard from "@/components/CourseCard.vue";
import ProgramCard from "@/components/ProgramCard.vue";

@Component<CourseCatalogIndex>({
  metaInfo: {
    title: "Eğitim Kataloğu"
  },
  components: {
    Breadcrumbs,
    CourseCard,
    ProgramCard
  },
  watch: {
    async selectedCategories() {
      await this.getCourses();
    }
  }
})
export default class CourseCatalogIndex extends Vue {
  breadcrumbs = [
    {
      text: "Ana Sayfa",
      exact: true,
      to: { name: "Home" }
    },
    {
      text: "Eğitim Kataloğu"
    }
  ];

  courses = [] as Course[];
  categories = [] as CourseCategory[];
  selectedCategories = [] as string[];
  options = {};
  
  progs = [
    {
      image: "@/assets/kooperatifcilik.jpg",
      href: "/program-katalogu/kooperatifcilik",
      name: "Kooperatifçilik Eğitim Programı",
      price: 1500
    },
    {
      image: "@/assets/yeminli-sosyal-arabuluculuk.png",
      href: "/program-katalogu/hegem-yeminli-sosyal-arabuluculuk",
      name: "Yeminli Sosyal Arabuluculuk Sertifika Programı",
      price: "1500"
    },
    {
      image: "@/assets/siddeti-onleme-ve-insan-haklari-danismanligi.png",
      href: "/program-katalogu/hegem-siddeti-onleme-ve-insan-haklari-danismanligi",
      name: "Şiddeti Önleme ve İnsan Hakları Danışmanlığı Sertifika Programı",
      price: 1200
    }
  ];

  loading = false;
  itemsPerPageArray = [10, 20, 40];
  itemsPerPage = 10;
  page = 1;
  search = "";
  filter = {};
  sortBy = "startDate";
  sortDesc = true;
  keys = [
    { label: "Eğitim Adı", value: "name" },
    { label: "Eğitmen", value: "instructor" },
    { label: "Başlangıç Tarihi", value: "startDate" }
  ];

  get numberOfPages(): number {
    return Math.ceil(this.courses.length / this.itemsPerPage);
  }

  nextPage(): void {
    if (this.page + 1 <= this.numberOfPages) this.page += 1;
  }

  formerPage(): void {
    if (this.page - 1 >= 1) this.page -= 1;
  }

  updateItemsPerPage(x: number): void {
    this.itemsPerPage = x;
  }

  customSort(items: any, index: any, isDesc: any) {
    items.sort((a: any, b: any) => {
      if (index[0] == "startDate") {
        if (isDesc[0]) {
          return b[index] - a[index];
        } else {
          return a[index] - b[index];
        }
      } else {
        if (typeof a[index] !== "undefined") {
          if (!isDesc[0]) {
            return a[index].toLowerCase().localeCompare(b[index].toLowerCase());
          } else {
            return b[index].toLowerCase().localeCompare(a[index].toLowerCase());
          }
        }
      }
    });

    return items;
  }

  async getCourses(): Promise<void> {
    try {
      this.loading = true;

      const authHeader = this.$store.getters["auth/requestAuthHeader"];
      const res = await axios.get("/api/course/get-all", {
        headers: authHeader
      });

      const courses = res.data as Course[];

      courses.forEach((course, i) => {
        if (course.startDate) {
          course.startDate = new Date(course.startDate);
        }

        if (course.finishDate) {
          course.finishDate = new Date(course.finishDate);
        }

        // Yayında değil ise
        if (!course.disabled) {
          this.courses.push(course);
        }
      });

      console.log(this.courses);
    } catch (e: unknown) {
      this.$notify({
        type: "error",
        title: "Hata!",
        text: `Ders listesi alınamadı.`
      });
    } finally {
      this.loading = false;
    }
  }

  async getAllCategories(): Promise<void> {
    this.categories = [
      {
        id: "1",
        name: "Genel",
        urlCode: "genel"
      },
      {
        id: "2",
        name: "Kişisel Gelişim",
        urlCode: "kisisel-gelisim"
      }
    ];
  }

  async mounted(): Promise<void> {
    await this.getAllCategories();
    await this.getCourses();
  }
}
