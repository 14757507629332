


























import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class ProgramCard extends Vue {
  @Prop() image!: string;
  @Prop() href!: string;
  @Prop() name!: string;
  @Prop() background!: string;
  @Prop() startDate!: Date;
  @Prop() finishDate!: Date | null;
  @Prop() price!: number;
}
