





























import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class CourseCard extends Vue {
  @Prop() image!: string;
  @Prop() urlCode!: string;
  @Prop() name!: string;
  @Prop() background!: string;
  @Prop() startDate!: Date;
  @Prop() finishDate!: Date | null;
  @Prop() price!: number;

  get imagePath(): string {
    return `${this.$store.getters["uploadFolder"]}/${this.image}`;
  }
}
